import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ayat-jurnal"
    }}>{`Ayat Jurnal`}</h1>
    <p>{`Ayat Jurnal adalah formulir yang berfungsi untuk mencatat transaksi-transaksi keuangan Perusahaan. Pada Flits ini disediakan sebanyak lima tipe, diantaranya ialah Tipe Jurnal :`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Penjualan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tipe Jurnal Penjualan biasanya digunakan untuk mencatat transaksi penjualan barang/jasa kepada Customer. Dokumen pada Flits yang terintegrasi dengan Ayat Jurnal tipe ini adalah Customer Invoice.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pembelian`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tipe Jurnal Pembelian biasanya digunakan untuk mencatat transaksi pembelian barang/jasa kepada Vendor/Supplier. Dokumen pada Flits yang terintegrasi dengan Ayat Jurnal Tipe ini adalah Vendor Bill dan Refund Pembelian.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bank`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tipe Jurnal Bank biasanya digunakan untuk mencatat transaksi pembayaran baik berupa pembayaran atas pembelian, penjualan ataupun internal transfer yang dilakukan oleh perusahaan itu sendiri.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kas`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tipe Jurnal Kas pada dasarnya sama dengan Tipe Bank yang membedakan hanyalah jenis daripada jenis penyimpanan uangnya berupad Bank/Kas.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lain-lain`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tipe Jurnal Lain-lain.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "formulir-ayat-jurnal"
    }}>{`Formulir Ayat Jurnal`}</h2>
    <img src='/public/img/Ayat Jurnal/Form Ayat Jurnal.png' />
    <h3 {...{
      "id": "kolom-kolom-pada-ayat-jurnal"
    }}>{`Kolom-kolom Pada Ayat Jurnal`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan nomor referensi dokumen lain`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tanggal Akutansi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk mengatur tanggal diterbitkannya Jurnal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tipe Jurnal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih tipe jurnal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada ayat jurnal`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "detail-tabel-refund-pembelian"
    }}>{`Detail Tabel Refund Pembelian`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Akun`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih CoA untuk setiap line`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Partner`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih partner`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kendaraan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memilih kendaraan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Deskripsi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan pada masing-masing line`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Pajak untuk setiap barang / item`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Debit`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk mencatat nominal uang yang masuk (Total debit harus sama dengan total kredit)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Kredit`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk mencatat nominal uang yang keluar (Total kredit harus sama dengan total debit)`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "list-ayat-jurnal"
    }}>{`List Ayat Jurnal`}</h2>
    <p>{`Dokumen Refund Pembelian yang telah dibuat pada database, akan disimpan atau direkap dalam satu tabel yang disebut List Refund Pembelian. List ini juga bisa dikatakan sebagai Overview daripada Refund Pembelian`}</p>
    <p>{`List Refund Pembelian dapat diakses dengan cara klik Hutamg Pada Side Menu > Refund Pembelian`}</p>
    <img src='/public/img/Ayat Jurnal/List Ayat Jurnal.png' />
    <p>{`Tampilan List Refund Pembelian terbagi menjadi dua bagian yaitu :`}</p>
    <p>{`Tombol menu pada List Refund Pembelian, terdiri dari :`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Buat`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk membuat dokumen Refund baru`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Import`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk menginputkan dokumen Refund secara massal dalam sekali import`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Filter`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdapat dua jenis filter yaitu by Status dan by Periode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Search bar`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field yang dapat digunakan untuk melakukan pencarian terhadap dokumen yang diinginkan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Atur Halaman`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigasi Next or Previous Page`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`List Refund Pembelian Detail, terdiri dari :`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Refund#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nomor Dokumen Refund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Vendor`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nama vendor yang tertera pada Refund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Refund`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal diterbitkannya Refund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Jatuh Tempo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal Jatuh Tempo`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catatan tambahan untuk dokumen Refund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`DPP`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total tagihan sebelum pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total Pajak pada Refund`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan pada Refund setelah dikenakan Pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sisa Tagihan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Jumlah tagihan yang belum dilakukan pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Status`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdiri dari empat status, yakni :`}<ul><li>{`Draft`}<div>{`Dokumen bisa dilakukan perubahan`}</div></li><li>{`Open`}<div>{`Dokumen yang sudah dilakukan Approval dan dapat dilakukan pembayaran`}</div></li><li>{`Paid`}<div>{`Dokumen telah dibayar Lunas`}</div></li><li>{`Cancelled`}<div>{`Dokumen dibatalkan setelah dilakukan Approval`}</div></li></ul></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-refund-pembelian"
    }}>{`Membuat Refund Pembelian`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Refund Pembelian yang dapat digunakan sebagai transaksi pengembalian barang kepada Vendor :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Vendor Bill dengan klik Hutang Pada Side Menu > Refund Pembelian > Pada list Refund Pembelian klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Refund Pembelian seperti gambar berikut :`}</p>
  <img src='/public/img/Ayat Jurnal/Bikin Ayat Jurnal.png' />
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Pilih Nama Vendor`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Isi Tanggal diterbitkannya Refund`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Syarat Pembayaraan (Jumlah hari sebelum Jatuh tempo)`}</p>
            <img src='/public/img/Ayat Jurnal/Bikin Ayat Jurnal Detail.png' />
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Nama barang yang akan dibuatkan Bill`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih CoA untuk setiap Item`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih kendaraan jika item yang dipilih ada bersangkutan dengan salah satu kendaraan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan jumlah barang yang dibeli`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan Harga satuan tiap item`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Mambahkan Pajak jika diperlukan`}</p>
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi sesuai dengan pesanan, klik tombol Save untuk menyimpan dokumen Refund yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Refund menjadi “Open”.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Refund Pembelian ini di Confirm maka akan otomatis membentuk Jurnal Tipe Pembelian dengan status Posted.`}</p>
    <h2 {...{
      "id": "membatalkan-dokumen-setelah-approval"
    }}>{`Membatalkan Dokumen Setelah Approval`}</h2>
    <p>{`Pembatalan dokumen Refund Pembelian yang dimaksud disini adalah tindakan yang membuat dokumen tersebut tidak dapat lagi dipakai sebagai dokumen transaksi tanpa menghapus dokumen tersebut. Sehingga dokumen Refund Pembelian tersebut masih tetap bisa ditelusuri pada List Refund Pembelian.`}</p>
    <p>{`Pembatalan dokumen Refund Pembelian dapat dilakukan melalui langkah-langkah berikut :`}</p>
    <ol>
      <li parentName="ol">{`Buka List Refund Pembelian ( Klik Hutang pada side menu > Refund Pembelian )`}</li>
      <li parentName="ol">{`Cari Nomor Refund yang dimaksud menggunakan fitur search, kemudian klik Nomor Refundnya`}</li>
      <li parentName="ol">{`Klik dropdown Action > Cancel `}</li>
      <li parentName="ol">{`Kemudian setelah muncul pop up konfirmasi cancel dokumen klik tombol yes`}</li>
    </ol>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Untuk dokumen Refund yang dapat dibatalkan adalah dokumen yang statusnya “Open” dan belum ada sama sekali pembayaran. Tindakan ini juga akan membuat Jurnal yang terintegrasi akan berubah status menjadi Cancelled.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      